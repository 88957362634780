<template>
    <v-dialog
        v-model="showDialog"
        max-width="500px"
    >
        <v-card>
            <v-card-title>
                <span
                    class="text-h5"
                >
                    <slot name="title">{{ $t('purposes.filterGroup.title') }}</slot>
                </span>
            </v-card-title>
            <v-card-subtitle>
                <span
                    class="text-h7 ml-2"
                >
                    <slot name="subtitle">{{ $t('purposes.filterGroup.subtitle') }}</slot>
                </span>
            </v-card-subtitle>

            <!-- Edited roles-->
            <v-card-text>
                <!--              return-object-->
                <v-treeview
                    v-model="itemsSelected_"
                    :open="initiallyOpen"
                    :items="items"
                    item-key="id"
                    selected-color="indigo"
                    open-on-click
                    selectable

                    expand-icon="mdi-chevron-down"
                    on-icon="mdi-bookmark"
                    off-icon="mdi-bookmark-outline"
                    indeterminate-icon="mdi-bookmark-minus"
                >
                    <!--                    <template v-slot:prepend="{ item, open }">
                        <v-icon v-if="!item.file">
                            {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
                        </v-icon>
                        <v-icon v-else>
                            {{ files[item.file] }}
                        </v-icon>
                    </template>-->
                </v-treeview>
            </v-card-text>

            <v-card-actions>
                <v-spacer />
                <v-btn
                    color="blue darken-1"
                    text
                    @click="itemsSelected_ = []"
                >
                    <slot name="cleanFilters">
                        {{ $t('purposes.filterGroup.cleanFilters') }}
                    </slot>
                </v-btn>

                <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                >
                    {{ $t('common.close') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from '@/plugins/axios'

export default {
    name: 'GroupsTreeDialogSelect',
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        itemsSelected: {
            type: Array,
            default() {
                return [];
            },
            required: true
        },
        itemsAllowed: {
            type: Object,
            default() {
                return {};
            },
            required: false
        }
    },
    data: function () {
        return {
            initiallyOpen: ['public'],
            items: []
        }
    },
    computed: {
        itemsSelected_: {
            get() {
                return this.itemsSelected;
            },
            set(value) {
                this.$emit('update:itemsSelected', value ?? []);
            }
        },
        showDialog: {
            get() {
                return this.dialog;
            },
            set(val) {
                this.$emit('update:dialog', false)
            }
        }
    },
    /*  watch: {
        itemsSelected_: {
            handler(newValue, oldValue) {
                this.$emit('update:itemsSelected', newValue);
            }

        }

    }, */
    created() {
        this.fetchGroups();
    },
    methods: {
        /* remove(item) {
            this.itemsSelected_ = this.itemsSelected_.filter(e => item.id !== e);
        }, */
        async fetchGroups() {
            try {
                const groups = await axios
                    .get('/groups')

                const data = groups.data.data;

                const map = {};
                const items = [];

                const filterAllowed = !!Object.keys(this.itemsAllowed).length;

                for (let i = 0; i < data.length; i++) {
                    const item = data[i];

                    // if the filter is not active or the item is allowed
                    if (filterAllowed) {
                        if (this.itemsAllowed[item.id]) {
                            items.push(item);
                        }
                    } else {
                        const parentId = item.attributes["parent-id"];
                        if (parentId) {
                            if (!map[parentId]) map[parentId] = [];

                            map[parentId].push(item);
                        } else {
                            items.push(item);
                        }
                    }
                }

                this.items = this.fullfillTree(items, map);

                /* console.log('***** fullfillTree *********');
                console.log(this.items); */
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        },
        fullfillTree(items, map) {
            const result = [];
            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                const parent = { id: item.id, name: item.attributes.name };

                if (map[item.id]) {
                    const children = map[item.id];
                    if (children && children.length) {
                        parent.children = this.fullfillTree(children, map);
                    }
                }

                result.push(parent)
            }

            return result;
        },
        close() {
            this.$emit('update:dialog', false)
        }
    }
}
</script>

<style>
.v-subheader {
  align-items: center;
  display: flex;
  height: 20px;
  font-size: 0.875rem;
  font-weight: 400;
  padding: 10px 16px 0 16px;
}
</style>
