<template>
    <div>
        <user-component />
    </div>
</template>

<script>
import UserComponent from '@/components/admin/users/usersList'
export default {
    name: "AdminUsers",
    components: { UserComponent },
    data: () => ({

    })
};
</script>
