<template>
    <v-form>
        <v-container class="py-0">
            <v-row
                v-for="(group, index) in permissionsFullGrouped"
                :key="index"
            >
                <v-col
                    cols="6"
                    sm="6"
                    class="text-right"
                >
                    <!--                              v-model="editedItem.enable"-->
                    <label class="mx-2">
                        {{ getPermissionName(group[0].display_name).toUpperCase() }}
                    </label>
                </v-col>

                <!--                          offset-sm="4"-->
                <v-col
                    cols="6"
                    sm="6"
                >
                    <v-row>
                        <v-col
                            v-for="item in group"
                            :key="item.id"
                            class="my-0 py-0"
                            cols="12"
                            sm="12"
                        >
                            <!--                              -->
                            <v-switch
                                v-model="itemsSelected_[item.id]"
                                class="my-0 py-0 mx-2"
                                :label="item.display_name.split(' ')[0]"
                                @change="changeState(item.id, $event)"
                            />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>

export default {
    props: {
        itemsSelected: {
            type: Array,
            default() {
                return [];
            },
            required: true
        }
    },
    data: function () {
        return {
            urlMain: '/permissions'
        }
    },

    computed: {
        itemsSelected_: {
            get() {
                return this.itemsSelected;
            }/*,
            set(value) {
                this.$emit('update:itemsSelected', value);
            } */
        },
        permissionsFullGrouped: {
            get() {
                const perm = this.$store.getters['permissions/getPermissions'];

                const result = [];
                let group = [];
                for (let i = 0; i < perm.length; i++) {
                    if (i > 0 && this.getPermissionName(perm[i - 1].name) !== this.getPermissionName(perm[i].name)) {
                        result.push(group);
                        group = [];
                    }

                    group.push(perm[i]);
                }

                if (group.length) {
                    result.push(group);
                }

                return result;
            },
            set(val) {
                return true
            }
        }
        // ...mapGetters('permissions', ['getPermissions'])
    },

    // Fetches posts when the component is created.
    created() {
        this.$store.dispatch('permissions/loadPermissions');
    },

    mounted() {

    },

    methods: {
        async changeState(id, status) {
            this.$emit('update:permission', status, id);
        },
        getPermissionName(name) {
            /* console.log(item)
    console.log('******* item') */

            return name.substring(
                name.indexOf(' ') + 1);
        }
    }
}
</script>
